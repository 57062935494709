span.deleteicon {
  position: relative;
  display: inline-flex;
  align-items: center;
}

span.deleteicon div {
  position: absolute;
  display: block;
  height: 15px;
  right: 0;
  color: #212529;
  padding: 0 8px;
  font: 13px monospace;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
  top: 20%;
}

span.deleteicon div:hover svg {
  color: black;
}
span.deleteicon input {
  padding-right: 18px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  color: #212529;
  height: 32px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 8px;
  width: 100% !important;
}

span.deleteicon input:hover {
  border-color: hsl(0, 0%, 70%);
}

.deleteicon svg {
  color: hsl(0, 0%, 80%);
  transition: color 150ms;
}

.cancelableInput::placeholder {
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  color: #6c757d;
  opacity: 1;
}

.divContainer {
  height: 32px;
}

.pointer{
  cursor: pointer;
}