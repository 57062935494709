.credit-input {
  width: 200px;
  margin: 0 8px 0 8px;
}

#credits-select {
  width: 280px;
  margin: 0 8px 0 8px;
  font-size: 12px;
}

.credits-row {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0;
}

.credits-btn {
  margin: 0 8px 0 8px;
  font-size: 12px;
}

.credits-label {
  font-size: 11px;
}

.dark-credits-label {
  font-size: 11px;
  color: white;
}

.convert-points-card {
  width: 150px;
  cursor: pointer;
}

.grayed-out {
  opacity: 0.5;
  /* pointer-events: none;
    cursor: not-allowed; */
}

.convert-points-input {
  margin: 0 8px;
  width: 300px;
}

.points-page table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.points-page table td {
  font-size: 12px;
}

.points-page td,
.points-page th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
}

.points-page tr:nth-child(even) {
  background-color: #dddddd;
}

.dark-points-page table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.dark-points-page table td {
  font-size: 12px;
}

.dark-points-page td,
.dark-points-page th {
  border: 1px solid #dddddd;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
}

.dark-points-page tr:nth-child(even) {
  background-color: #dddddd;
}

.dark-points-page .col-form-label {
  color: white;
}

.dark-points-page .table-title h5 {
  color: white;
}

#page-number {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 30px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  font-weight: bold;
}

.transfer-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}

.credits td {
  font-size: 12px !important;
  white-space: normal !important;
}

.dark-table-striped .first-Row {
  background-color: #242526;
}

.dark-card-home .header-text {
  color: white;
}

.dark-card .semi-nav {
  color: white !important;
}
