.jawwal-back {
  width: 100%;
  height: 100%;
  background: url(/src/assests/images/bundles/jawwal/banner.jpg) no-repeat
    center center;
  background-size: 100% 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.jawwal-text {
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #fff;
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0 16px;
}
[lang="en"] .jawwal-text {
  font-size: 1.2rem;
}
.mobile-semi {
  color: #fff;
}
.charge-card :hover {
  transform: translateY(0) scale(1.02);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  opacity: 0.9;
}
.fixed-top1 {
  top: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 0.95;
  min-height: 130px;
}
.frame1 {
  position: relative;
  height: auto;
}
.frame1 > img {
  object-fit: contain;
}
.close-btn {
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
  display: inline-flex;
  width: 20px;
  height: 20px;
  text-decoration: none;
  background-color: red;
  align-items: center;
  justify-content: center;
}
.text-abs {
  position: absolute;
  top: 50%;
  left: calc(50% + 5px);
  font-size: 2rem;
  font-weight: 900;
  color: #14c259;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  transform: translateY(-50%);
  text-align: center;
  width: calc(50% - 10px);
}
.close-btn > i {
  color: #fff;
}
.close-btn:hover > i {
  color: #000;
  transition: all 0.3s ease-in-out;
}
.active-sub {
  color: #42ace3;
  font-weight: 900;
  /* font-size: 1.3rem; */
  border: 0 !important;
}

.jawwal-sub .link-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jawwal-sub .active-sub {
  border-bottom: 3px solid #42ace3 !important;
  padding-bottom: 8px;
}

.sub-nav {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.sub-nav.dark {
  background: #242526;
}
.details {
  cursor: pointer;
  text-decoration: none;
}
.btn-light:hover {
  color: #42ace3 !important;
  /* color: #000 !important; */
}
.selected-btn {
  background-color: #42ace3 !important ;
  color: #fff !important;
}
.selected-btn:hover {
  color: #ffffff !important;
}
.filtter-rom {
  display: none;
}
.filtter-active {
  display: inherit;
}
.type-rom > button {
  width: 120px;
  height: 55px;
  overflow: hidden;
  text-transform: capitalize;
}
.rom-selected {
  background-color: #42ace3 !important ;
  color: #fff !important;
  font-weight: 900;
}
.btn-back {
  background-color: #fff !important ;
  color: #42ace3 !important;
  font-weight: 900;
}
.back-btn {
  color: #42ace3 !important ;
  background-color: #fff !important;
  font-weight: 900 !important;
}
.warning-text {
  color: red;
}
.refersh-but {
  font-weight: 900;
}
.total-balance-card {
  border-radius: 0.25rem;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}
.btn-renewable {
  font-family: "Times New Roman", "Montserrat", sans-serif;
  font-size: 1rem;
}

[dir="ltr"] .btn-group > .btn:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir="ltr"] .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-sm .card-credit .form-group input {
  font-size: 1rem !important;
}
.card-md .card-credit .form-group input {
  font-size: 2rem !important;
}
.card-lg .card-credit .form-group input {
  font-size: 2.5rem !important;
  right: 5% !important;
}
.list-cards .frame-card {
  width: 100%;
  height: 100%;
}
.list-cards .charge-card {
  margin-bottom: 10px;
}
.badge.left-100 {
  left: -10px;
}
.card.jawwal .nav-layout {
  background-color: #35a424;
}

.jawwal-plus {
  background-color: #32a323;
  color: white;
  border-radius: 1rem;
}

.selected-plus > div {
  height: 90px;
}

.selected-plus p {
  font-size: 10px;
}

.selected-plus img {
  height: 25px;
  width: 25px;
}

.dark-background {
  background: #242526;
}

.Company_Container {
  max-width: 400px;
  height: 175px;
  padding: 16px;
  margin-left: 16%;
  margin-right: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  border: 3px solid green;
  border-radius: 10px;
}

.Company_Container_Dark {
  max-width: 400px;
  padding: 16px;
  background-color: #242526;
  margin-left: 22%;
  margin-right: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  border: 3px solid green;
  border-radius: 10px;
}
.Company_Container_Red {
  max-width: 400px;
  height: 175px;
  padding: 16px;
  background-color: #242526;
  margin-left: 22%;
  margin-right: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  border: 3px solid red;
  border-radius: 10px;
}
.Company_Container_white_green {
  max-width: 400px;
  padding: 16px;
  margin-left: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  border: 3px solid green;
  border-radius: 10px;
  height: 175px;
}
.Company_Container_white_Red {
  max-width: 400px;
  padding: 16px;
  margin-left: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  border: 3px solid red;
  border-radius: 10px;
  height: 175px;
}
.Company_Logo {
  max-width: 160px;
  max-height: 100px;
  margin-inline: 8px;
}

.attention_pay_div_container {
  display: flex;
  justify-content: center;
}

.Company_Provider_Dark {
  padding-top: 16px;
  font-size: x-large;
  color: white;
}
.Company_Provider_Error {
  /* padding-top: 4px; */
  font-size: x-large;
  color: red;
}
.Company_Provider {
  padding-top: 16px;
  font-size: x-large;
  color: black;
}
.Correct_Provider_Logo {
  /* margin-right: 51.5%; */
  width: 250px;
  aspect-ratio: 1;
  margin-top: 25px;
}
.orginal_Company {
  text-align: center;
  margin-right: 30%;
}
.orginal_Company_Dark {
  text-align: center;
  margin-right: 30%;
  color: white;
}

.Company_Orginal_Logo {
  padding-right: 30%;
}

.logo_Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 720px;
  max-width: 950px;
}
.Fixed_logo_Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 720px;
  max-width: 950px;
}

.bottom_Logo {
  max-width: 80px;
  margin: 16px;
}
.Company_Dark_Nav {
  display: flex;
  align-items: center;
  background-color: #242526;
}
.Company_Nav {
  display: flex;
  align-items: center;
}
.jawwal_Nav_Logo {
  max-height: 50px;
  width: 40px;
  margin-right: 70%;
}
.Ooredoo_Nav_Logo {
  max-height: 50px;
  width: 40px;
  margin-right: 70%;
}

.Hot_Nav_Logo {
  max-height: 50px;
  width: 40px;
  margin-right: 70%;
}

.text-Continer .form-control {
  font-size: 25px;
}
