.wrapper .card.outer-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
}

.wrapper .card.outer-wrapper .frame > img {
  height: 150px;
}

.wrapper .card-sm .card.outer-wrapper .nav-layout {
  min-height: 110px;
}

.wrapper .card.outer-wrapper .nav-layout {
  flex-grow: 1;
}

.wrapper .card.outer-wrapper .nav-layout h5 {
  flex-grow: 1;
}

.wrapper a {
  text-decoration: unset;
}

.vehicle-stepper {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  align-items: center;
  position: relative;
  margin-top: 48px;
}

.vehicle-stepper .select-input {
  width: 350px;
  margin: 4px 0;
  margin-top: 16px;
  font-size: 14px;
}

.vehicle-stepper .form-group {
  width: 350px !important;
  margin-bottom: 4px;
}

.vehicle-stepper .form-control {
  border-width: 2.3px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  min-height: 34px;
  outline: none;
  transition: all 0.2s ease;
}

.vehicle-stepper .message-container h4 {
  justify-content: space-between;
}

.dark-message-container {
  background: #242526;
}

.vehicle-stepper .dark-message-container h4 {
  justify-content: space-between;
}

.vehicle-stepper .btn {
  position: absolute;
  top: 42px;
  right: 25px;
  width: 115px;
}

.vehicle-stepper .back {
  left: 25px;
}

.insurance-submit > div {
  margin-top: 20px;
}

.step {
  position: absolute;
  transform: translateX(-100vw);
  opacity: 0;
  transition: 400ms all;
  top: 70px;
}

.step.scrollable {
  width: 100%;
  height: 75%;
  overflow-y: auto;
}

.step.active {
  transform: translateX(0);
  opacity: 1;
}

.step.next {
  transform: translateX(100vw);
}

.insurance-submit-selections div[class$="-MenuList"] {
  max-height: 200px !important;
}

.insurance-submit-selections div[class$="-option"] {
  padding: 4px 12px !important;
}

.insurance-submit-selections .insurance-car-image {
  border: solid 1px #0d6efd;
  border-radius: 10%;
  margin: 5px;
  cursor: pointer;
}

.insurance-submit-selections .insurance-car-image:hover {
  border: solid 3px #0d6efd;
}
