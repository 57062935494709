label > .semi-nav {
  text-decoration: none;
  color: #000;
}

.active-semi {
  padding-bottom: 6px;
  color: #25ace3 !important;
  border-bottom: 2px solid #25ace3;
}

td {
  font-weight: bold;
}

.img-back {
  width: 100%;
  height: 135px;
  margin-bottom: 0;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
}

.dark-img-back {
  width: 100%;
  height: 135px;
  margin-bottom: 0;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  background-color: #242526;
}

.dark-img-back h1 {
  color: white;
}

.header-text {
  font-weight: bold;
  color: #212529;
  margin: 0;
}

.dark-header-text {
  font-weight: bold;
  color: white;
  margin: 0;
  background: #242526;
}

.no-data-to-show {
  font-size: 18px;
  margin-top: 16px;
  width: 100%;
}

.no-data-to-show-darkmode {
  font-size: 18px;
  margin-top: 16px;
  margin-right: 32px;
  width: 100%;
  color: white;
}

.dark-table-wrapper .no-data-to-show {
  color: white;
}

.container-report {
  padding: 0 64px 0 64px;
}

.report-search-btn {
  width: 180px;
}

.report-filter-item {
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
}

.report-label {
  margin-top: 8px;
}

.left-column {
  height: 2160px;
}

.report-input {
  width: 160px;
  height: 32px;
}

.col-11 {
  width: 870px;
}

.report-form-control {
  /* height: 32px; */
}
.report-input-left-column {
  height: 32px;
}

.report-form-control-date-picker {
  height: 32px;
  width: 160px;
}

.report-dropdown {
  height: 32px !important;
}

.report-dropdown-container {
  width: 15%;
  font-size: 12px;
}

.first-row .form-control {
  width: 15%;
  font-size: 12px;
  height: 32px;
}

.report-row {
  display: flex;
  flex-direction: row;
}

.report-column {
  margin: 0 16px 0 8px;
}

.report-checkbox {
  padding: 0px !important;
}

.second-row .section-one {
  width: 32%;
}

.second-row .form-control {
  font-size: 12px;
  height: 32px;
}

.second-row .btn {
  padding: 0;
  width: 47%;
}

.section-one input {
  width: 48%;
}

.second-row .section-two {
  width: 32%;
}

.section-two .react-datepicker-wrapper {
  width: 43%;
}

.section-two .report-form-control-date-picker {
  width: 100%;
}

.section-two h6 {
  margin-bottom: 0;
  font-size: 14px;
}

.third-row .section-one {
  width: 80%;
}

.section-one .report-checkbox-container {
  /* margin: 0 24px; */
}

.section-one .report-checkbox-container:first-child {
  margin: 0;
}

.report-checkbox-container label {
  margin: 0 8px;
  font-size: 14px;
}

.table-striped th,
.table-striped td {
  text-align: center;
}

.dark-table-striped th,
.dark-table-striped td {
  text-align: center;
  color: gray;
}

.table-striped td {
  font-size: 13px;
  color: #2c3136;
  white-space: nowrap;
}

.dark-table-striped td {
  font-size: 13px;
  color: white;
  white-space: nowrap;
}

.dark-input input {
  background: transparent;
}

.dark-input ::-webkit-input-placeholder {
  color: white;
}

.dark-input svg {
  color: white;
}

.dark-input label {
  color: white;
}

.dark-input .Dropdown-control {
  background: #242526;
}

.dark-input .form-control {
  background: #242526;
  color: white;
}

.react-datepicker-popper {
  z-index: 10 !important;
}
.dark-input .react-datepicker-popper {
  background: #242526;
  color: white;
}

.dark-input .react-datepicker__month {
  background: #242526;
}

.dark-input .react-datepicker__header {
  background: #242526;
}

.dark-input .Dropdown-placeholder {
  color: white;
}

.dark-input .Dropdown-menu {
  background: #242526;
}

.dark-input h6 {
  color: white;
}

.dark-input .Dropdown-menu .Dropdown-option {
  color: white;
}

.dark-input .Dropdown-menu .Dropdown-option.is-selected {
  color: white;
  background: #242526;
}

.table-striped svg {
  transition: all 200ms;
}

.dark-table-striped svg {
  transition: all 200ms;
  transform: rotate(180deg);
}

.table-striped .flip {
  transition: all 200ms;
  transform: rotate(0deg);
}

.dark-table-striped .flip {
  transition: all 200ms;
  transform: rotate(0deg);
}

.hover-cursor:hover {
  cursor: pointer;
}

.report-dropdown-container .Dropdown-control {
  padding: 6px 10px;
  font-size: 12px;
}

.report-dropdown-container > div > div {
  min-height: 32px;
  border-color: #ced4da;
}

.report-dropdown-container > div > div > div > div {
  padding: 0 8px;
}

.Dropdown-disabled {
  background-color: #e9ecef;
  cursor: no-drop;
}

.form-control:disabled {
  cursor: no-drop;
}

.dark-input label {
  color: white;
}

.dark-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #242526;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.table-details {
  display: flex;
}

.table-details .list-label {
  flex: 0 0 40%;
  text-align: start;
}

.table-details > div {
  white-space: break-spaces;
  text-align: start;
}

.table-details .list-label {
  flex: 0 0 40%;
  text-align: start;
  color: rgb(106 115 131);
}

.date-picker-div-container {
  display: flex !important;
  justify-content: flex-start !important;
  gap: 2px;
}

.date-picker-div-container > div {
  max-width: 307px;
}

.date-picker-div-container input {
  font-size: 12px;
}

.topUpDropDown {
  width: 48%;
}

.carrierLabelDiv {
  text-align: center;
  padding-top: 4px;
}

.topUp-account-container {
  width: 20%;
}

.topUp-account-container > div {
  width: 100%;
}
