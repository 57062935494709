.cart {
  padding: 12px;
  background-color: #e1edf1;
  border: 2px solid lightblue;
}

.card-summary>div {
  border-bottom: 1px solid gainsboro;
  padding: 12px 0;
}

.dark-card-summary>div {
  border-bottom: 1px solid gainsboro;
  padding: 12px 0;
  color: white;
}

.card-summary h6 {
  margin-bottom: 0;
  margin-left: 20px;
}

.dark-card-summary h6 {
  margin-bottom: 0;
  margin-left: 20px;
  color: "white"
}

.bg-dark {
  background: #242526;
}

.bg-dark h1 {
  color: white;
}