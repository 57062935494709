.dark-AdminMainPage {
  background: #18191a;
  height: 100vw;
  width: 100%;
}

.dark-AdminMainPage h1 {
  color: white;
  /* padding: 24px 0 0 24px; */
}

.admin-sidebar2 {
  height: 100%;
  padding-right: 0;
  border-right: 1px solid #c4c4c4;
  background-color: #263238;
  min-height: 100vh;
}

.dark-admin-sidebar2 {
  height: 100%;
  padding-right: 0;
  border-right: 1px solid #c4c4c4;
  background-color: #161b22;
  min-height: 100vh;
}

.admin-sidebar2 a {
  text-decoration: none;
  color: rgba(0, 0, 0.87);
}

.dark-admin-sidebar2 a {
  text-decoration: none;
  color: rgba(0, 0, 0.87);
}

.admin-sidebar2 svg {
  margin-right: 16px;
}

.admin-sidebar2-Logo {
  height: 67px;
  position: relative;
  margin-bottom: 36px;
  background: #324148;
}

.dark-admin-sidebar2-Logo {
  height: 67px;
  position: relative;
  margin-bottom: 36px;
  background: #2b323b;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 32px;
  width: 100%;
}

.dark-admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 32px;
  width: 100%;
  background: #161b22;
}

.admin-Icon-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.admin-Icon-name h4 {
  padding: 0 32px;
  padding-top: 10px;
}

.admin-header .admin-Icon-name h4 {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}

.dark-admin-header .admin-Icon-name h4 {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

.dark-admin-header-logout {
  color: white;
}

.sublinks-wrapper.admin a {
  width: 200px;
  padding: 8px 0;
  text-decoration: none;
  color: black;
}

.admin-logo {
  position: absolute;
  padding-left: 24px;
  top: 20px;
}

.AdminChart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 100%; */
}

.AdminChart .PieChart {
  margin-top: 50px;
}

.dark-input .react-datepicker {
  background-color: #242526;
}

.dark-input .react-datepicker__current-month,
.dark-input .react-datepicker__day-name,
.dark-input .react-datepicker__day {
  color: white;
}

.dark-admin-header,
admin-header {
  max-width: 1630px;
}

.dark-card-summary.EditValue {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  height: 80%;
}

.dark-AdminMainPage h6 {
  color: white;
}
