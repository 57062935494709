.outer-wrapper {
  width: 100%;
  height: 150px;
  border: 1px dashed #25ace3;
}

.dark-outer-wrapper {
  width: 100%;
  /* height: 150px; */
  border: 1px dashed #242526;
}

.outer-wrapper .frame {
  display: block;
  width: 100%;
  padding: 5px;
}

.dark-outer-wrapper .frame {
  display: block;
  width: 100%;
  padding: 5px;
}

.outer-wrapper .frame>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dark-outer-wrapper .frame>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav-layout h5 {
  font-size: 1rem;
  direction: ltr;
}

.table-green {
  /* background-color: #c3efd2; */
  background-color: #c3efd2;
}

.table-canceled {
  background-color: #c4df50;
}

.table-active {
  background-color: whitesmoke;
}