.css-1pahdxg-control{
  color: #212529 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  border-color: #86b7fe !important;
  background-color: #fff !important;
}

.report-dropdown-container {
  width: 100%;
}

.report-dropdown > div:hover {
  color: #212529 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  border-color: #86b7fe !important;
  background-color: #fff !important;
}

.report-dropdown-container .Dropdown-control {
  padding: 6px 10px;
  font-size: 12px;
}

.report-dropdown-container > div > div {
  min-height: 32px;
  border-color: #ced4da;
}

.report-dropdown-container > div > div > div > div {
  padding: 0 8px;
}
