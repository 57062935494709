.auth-header-container {
  margin-top: 16px;
  padding: 16px 0px;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f7f9fb;
}

.dark-auth-header-container {
  margin-top: 16px;
  padding: 16px 80px;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #161b22;
}

.auth-header-container i {
  font-size: 22px;
}

.dark-auth-header-container i {
  font-size: 22px;
  color: white;
}

.sublinks-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  min-height: 200px;
  z-index: 10001;
}

.sublinks-wrapper a {
  width: 150px;
  padding: 8px 0;
  text-decoration: none;
  color: black;
  line-height: 1.6;
}

.link-main:hover .MuiTypography-root,
.dark-link-main:hover .MuiTypography-root {
  font-size: 16px; /* Adjust as needed */
}
