/* @import url("https://use.fontawesome.com/releases/v5.15.1/css/all.css"); */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body.padding-class {
  padding-right: 17px;
}

.dark-AppBody {
  background: #18191a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pp-notiflix {
  position: fixed;
  z-index: 99999999;
  right: 10px;
  top: 50px !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.hidden {
  display: none !important;
}

.phoneplay-container {
  max-width: 1440px;
  margin: auto;
}

@media only screen and (max-width: 1400px) {
  .phoneplay-container {
    max-width: 1320px;
  }
}

@media only screen and (max-width: 1200px) {
  .phoneplay-container {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 992px) {
  .phoneplay-container {
    max-width: 880px;
  }
}

@media only screen and (max-width: 768px) {
  .phoneplay-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 576px) {
  .phoneplay-container {
    max-width: 540px;
  }
}

button:disabled,
button[disabled] {
  pointer-events: initial !important;
  cursor: no-drop;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-wrapper {
  padding: 28px;
  border: 1px solid gainsboro;
  margin: 36px 0;
  border-radius: 0.25rem;
  overflow: hidden;
  overflow-x: scroll;
}

.dark-table-wrapper {
  padding: 28px;
  border: 1px solid gainsboro;
  margin: 36px 0;
  border-radius: 0.25rem;
  background: #242526;
  overflow: hidden;
  overflow-x: scroll;
}

.table-title {
  width: 50%;
  padding-left: 0;
  padding-bottom: 8px;
  margin-bottom: 26px;
  border-bottom: 1px solid #25ace3;
}

.dark-table-wrapper .table-title {
  width: 50%;
  padding-left: 0;
  padding-bottom: 8px;
  margin-bottom: 26px;
  border-bottom: 1px solid white;
}

.table-title h5 {
  color: #25ace3;
}

.dark-table-wrapper .table-title h5 {
  color: white;
}

.table thead {
  background-color: #e9eaec;
}

.inner-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.phone-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 16px;
  padding-bottom: 0;
}

.phone-form .select-input {
  width: 350px;
  margin: 4px 0;
  margin-top: 16px;
  font-size: 14px;
}

.phone-form .form-group {
  width: 350px !important;
  margin: auto;
  margin-bottom: 16px;
}

.phone-form .form-control {
  border-width: 2.3px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  min-height: 34px;
  outline: none;
  transition: all 0.2s ease;
}

#notification-bar {
  position: fixed;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 35px;
  z-index: 999999;
  width: 40px;
  height: 40px;
}

#openNC {
  left: 3px !important;
  top: 2px !important;
  filter: invert(256%) sepia(27%) saturate(542%) hue-rotate(47deg)
    brightness(91%) contrast(114%);
}

.company-dropdown {
  position: absolute;
}

.company-dropdown > div {
  top: 25px;
  left: 0;
  min-width: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 11;
}

.company-dropdown > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.company-dropdown > div > div:hover {
  background-color: gainsboro;
}

.company-dropdown a {
  text-decoration: none;
  color: black;
  width: 100%;
  text-align: center;
}

.card-body p {
  text-align: center;
  margin-top: 16px;
}

@page {
  size: A4;
  padding: 2mm;
  margin: 0;
  margin-top: -3em;
}
@media print {
  .MuiBox-root {
    background: #000;
  }
  .btn {
    display: none;
    visibility: hidden;
  }
  .css-83cipe {
    width: 100vw;
  }
  .dark-card-body p {
    text-align: center;
    margin-top: 16px;
  }
}
.admin-fixed-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}

.admin-panel-Page {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
}

.item-translate ~ .settings-tools {
  display: none;
}

.dark,
.white {
  max-width: 1630px;
}

#siteseal {
  display: none;
}

.MuiPaper-root {
  padding: 16px;
  margin-bottom: 16px;
}

.MuiDivider-root {
  margin-bottom: 16px;
}

/* iframe {
  pointer-events: none;
} */
