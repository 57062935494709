.profile-page input {
  width: 350px;
}

.dark-profile-page input {
  width: 350px;
}

.profile-page .form-group {
  margin-top: 24px;
}

.dark-profile-page .form-group {
  margin-top: 24px;
}


.profile-page h6 {
  font-weight: normal;
  font-size: 0.9rem;
}

.dark-profile-page h3 {
  color: white
}

.dark-profile-page h5 {
  color: white
}

.dark-profile-page h6 {
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
}

.profile-page h6 span {
  font-weight: bold;
  font-size: 1.1rem;

}

.dark-profile-page h6 span {
  font-weight: bold;
  font-size: 1.1rem;
  color: white;
}

.dark-profile-page td {
  color: white !important;
}

.dark-tabel tr, .dark-tabel td, .dark-tabel th {
  color: white !important;
}