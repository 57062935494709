.subnav .pp-typing {
  padding: 10px;
  background: #e9eaec;
  border-radius: 0.25rem;
  font-size: 14px;
}

.subnav .pp-dark-typing {
  padding: 10px;
  background: #242526;
  border-radius: 0.25rem;
  font-size: 14px;
}