.submited-data-list li {
    margin-bottom: 7px;
}

.UpdateMointorContiner {
    padding-left: 130px;
    padding-top: 80px;
}


.dark-PeletalkTabs {
    background: #161B22;
}

.dark-PeletalkTabsTab {
    color: white
}

.dark-translate h2 {
    color: white;
}

.mt-4.dark th {
    color: white;
}

.mt-4.dark tr td {
    color: white;
}

.mt-4.dark td button {
    color: white;
}