.contact-us {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;
}

.contact-us>div {
  margin-bottom: 16px;
}

.contact-us label {
  left: auto !important;
}

.contact-us_message {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 69px;
}

.contact-us_subject {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.dark-contect-us h2 {
  color: white;
}


.dark-contect-us .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: white;
}

.dark-contect-us .css-1x51dt5-MuiInputBase-input-MuiInput-input:invalid {
  color: white;
}

.dark-contect-us .contact-us label {
  color: white;
}

.dark-contect-us .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: white;
}

.dark-contect-us .css-66dh3a-MuiInputBase-input-MuiInput-input {
  color: white;
}

.dark-contect-us .user-info {
  background-color: #242526;
}

.dark-contect-us .text-secondary {
  color: white !important;
}