.card.hot .nav-layout {
  background-color: #d32a31;
}
.hot-back {
  width: 100%;
  height: 100%;
  background-image: url(/src/assests/images/bundles/hot/banner.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #25ace3;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.Company_Hot_Dark_Nav{
  display: flex;
  align-items: center;
  background-color: #242526;
}