.card.azy .nav-layout {
  background-color: #fcd30b;
}
.azy-back {
  width: 100%;
  height: 100%;
  background-image: url(/src/assests/images/bundles/mobile019/banner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #25ace3;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.card.azy .nav-layout h5 span {
  direction: ltr;
}
