[dir="rtl"] .pp-typing .Typist {
    direction: rtl;
}

[dir="rtl"] .pp-dark-typing .Typist {
    direction: rtl;
}

.pp-typing {
    margin: 0.5rem 0 1rem 0;
}

.pp-dark-typing {
    margin: 0.5rem 0 1rem 0;
}


.pp-dark-typing {
    background: #242526;
}

.pp-dark-typing span {
    color: white;
}