[dir="rtl"] .navbar-brand {
  margin-left: 1rem;
}
.navbar {
  padding: 3rem 0;
}
.nav-link {
  font-weight: 700 !important;
}

.ReactFlagsSelect-module_selectOptionValue__vS99- {
  color: #000 !important;
  font-weight: bold;
}

.nav-img {
  width: 120px;
  /* height: 30px; */
}

.nav-img > img {
  width: 100%;
  height: auto;
}

.custom-lang {
  padding-bottom: 0;
}

.custom-lang > button {
  border: none;
}

[dir="rtl"] .custom-lang > button > span > span:nth-child(2) {
  padding-right: 10px;
  padding-left: 0;
}

.custom-lang > ul {
  margin: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e7ebf0;
  border-radius: 0.25rem;
  padding: 0.25rem 0;
}

.custom-lang > ul > li {
  margin: 0;
  padding: 7px 20px;
}

[dir="rtl"] .custom-lang > ul > li > span > span:nth-child(2) {
  padding-right: 10px;
  padding-left: 0;
}

.Dropdown-root.style1 .Dropdown-control {
  color: #ffffff;
  background-color: transparent;
  border: none;
  padding: 8px 40px 8px 10px;
}

.Dropdown-root.style1 .Dropdown-control:hover {
  box-shadow: none;
}

.Dropdown-root.style1 .Dropdown-control .Dropdown-arrow {
  border-color: #fff transparent transparent;
}

.Dropdown-root.style1.is-open .Dropdown-control .Dropdown-arrow {
  border-color: transparent transparent #fff;
}

.Dropdown-root.style1 .Dropdown-menu {
  border: 1px solid #e7ebf0;
  border-radius: 0.25rem;
  padding: 0.25rem 0;
}

.Dropdown-root.style1 .Dropdown-menu .Dropdown-option {
  background-color: white;
  color: #000;
  text-align: center;
}

/* Notification Style */
._enwholeContainer {
  top: 0 !important;
}

.navbar {
  padding-bottom: 20px;
  /* width: 100vw; */
}

.dark-navbar-light .navbar-nav .nav-link.active {
  color: #e7ebf0;
}

.dark-card-body {
  background: #242526;
}

.navbar::before {
  content: "V_2.7.9";
  color: white;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background: -moz-linear-gradient(
    linear-gradient(rgba(238, 130, 238, 1), #9198e5, rgba(75, 0, 130, 1))
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(238, 130, 238, 1),
    #9198e5,
    rgba(75, 0, 130, 1)
  );
  background: linear-gradient(
    90deg,
    rgba(238, 130, 238, 1),
    #9198e5,
    rgba(75, 0, 130, 1)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000", endColorstr="#ee82ee", GradientType=1);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/* Add these styles to your CSS file or inside a <style> tag in your component */

.navbar-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); Darker background */
  z-index: 1000; /* Ensure it appears above other elements */
  padding: 10px;
}

.navbar-mobile .nav-link,
.navbar-mobile .nav-item {
  color: white; /* Ensure text is visible on the dark background */
  padding: 10px 0; /* Add some padding for better spacing */
}

.navbar-mobile .nav-item .btn {
  color: white; /* Ensure button text is visible */
  border-color: white; /* Ensure button border is visible */
}

.navbar-mobile .ControlledSwitches {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-mobile .ControlledSwitches p {
  color: white;
  padding-top: 16px;
}

.mobileMenu {
  gap: 10px;
  padding: 16px 16px;
}
