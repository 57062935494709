.settings-tools {
  position: fixed;
  top: 100px;
  right: -270px;
  z-index: 1300;
  width: 270px;
  color: #76838f;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.settings-tools.opened {
  right: 0;
}
.settings-tools .settings-tools-inner {
  height: 100%;
}
.settings-tools .settings-tools-toggle {
  position: absolute;
  top: -34px;
  left: -34px;
  padding: 10px 8px;
  font-size: 18px;
  cursor: pointer;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.09);
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
}
.settings-tools .settings-tools-toggle > i {
  color: #3e8ef7;
  display: block;
}
.settings-tools .settings-tools-content {
  height: 100%;
  padding: 5px 20px 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0 3px 3px;
}
.settings-tools .settings-tools-title {
  margin-top: 20px;
  margin-bottom: 8px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: #37474f;
}
.settings-tools .nav.nav-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.settings-tools .nav.nav-tabs-line {
  position: relative;
}
.settings-tools .nav.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.settings-tools ul.nav.nav-tabs.nav-tabs-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.settings-tools .nav-tabs .nav-item .nav-link.active {
  color: #3e8ef7;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #3e8ef7;
}
.settings-tools .nav-tabs .nav-link,
.settings-tools .nav-tabs .nav-link:focus {
  border: none;
}
.settings-tools .nav-tabs .nav-item .nav-link {
  color: #76838f !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.571429;
  padding: 10px 5px;
}
.settings-tools .nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 2px solid #ccd5db;
}
.settings-tools .custom-lang button {
  color: #37474f !important;
}

.settings-tools .Dropdown-root.style1 .Dropdown-control {
  color: #37474f !important;
}
.settings-tools .Dropdown-root.style1 .Dropdown-control .Dropdown-arrow {
  border-color: #37474f transparent transparent;
}
.settings-tools
  .Dropdown-root.style1.is-open
  .Dropdown-control
  .Dropdown-arrow {
  border-color: transparent transparent #37474f;
}

.tab-content > .active.options {
  display: flex !important;
  flex-direction: column;
  padding: 16px 0;
}

.tab-content > .active.options a {
  margin-bottom: 8px;
}

.navbar-nav .nav-item:not(:nth-child(2)) {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-item:nth-child(2) {
  line-height: 2.5;
}
