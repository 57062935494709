@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
  font-family: "Helvetica Neue W23-regular";
  src: url("../../assests//fonts/Helvetica\ Neue\ W23-bold.TTF");
}

@font-face {
  font-family: "Helvetica Neue W23-bold";
  src: url("../../assests/fonts/Helvetica\ Neue\ W23-regular.TTF");
}

[type="email"],
[type="number"],
[type="tel"],
[type="url"] {
  direction: unset !important;
}

body.english {
  font-family: "Montserrat", sans-serif;
  /* color: #000000; */
}

body.arabic {
  font-family: "Helvetica Neue W23-regular";
  /* color: #000000; */
}

span,
h1,
h2,
h3,
h5,
h6,
div,
td,
label,
tr,
table h4 {
  font-weight: bolder;
}

body,
html {
  background-color: #f7f9fb;
  height: 100%;
}

label {
  font-weight: 700;
}

a {
  font-weight: bold;
}

.card {
  border: none;
  word-wrap: normal !important;
  position: relative;
}

.dark-card {
  border: none;
  word-wrap: normal !important;
  position: relative;
  background-color: #242526;
  color: #d8d8d8;
}

.dark-card a,
.dark-card .link-main {
  color: #d8d8d8 !important;
  padding: 5px;
}

.dark-card h1 {
  color: white;
}

.card-bal {
  box-shadow: 0 3px 3px #42ace3 !important;
  border: none !important;
}

.sign-but {
  background-color: #25ace3;
  color: #fff;
  font-weight: bold !important;
}

.sign-but:hover {
  color: #fff;
}

.sign-text {
  font-weight: bolder;
  color: #45b4e3;
}

.main-text {
  color: #25ace3;
}

.main-header {
  font-weight: bolder;
}

.sidebar__link {
  color: #f3f4f6;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.link-main {
  text-decoration: none;
  color: #000000;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.dark-link-main {
  text-decoration: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.icon-main {
  color: #45b4e3;
}

.card-home {
  border: 10px #45b4e3 !important;
}

.active-link > span > a {
  font-weight: 900;
  color: #42ace3;
}

.nav-layout {
  background: #25ace3;
}

.dark-nav-layout {
  background: #242526;
}

.dark-nav-layout h5 {
  font-weight: bold;
  color: #fff;
  text-decoration: none !important;
  font-size: 1rem;
}

.nav-layout h5 {
  font-weight: bold;
  color: #fff;
  text-decoration: none !important;
}

.cards-layout {
  background: #fff;
  border: 1px solid #25ace3;
  border-top: 0;
}

.cards-layout > h5 {
  font-weight: bolder;
  font-size: 1.2rem;
  color: #25ace3;
  text-decoration: none !important;
}

.jawwal-layout {
  background: #fff;
  border: 1px solid #25ace3;
}

.jawwal-layout > div > label {
  font-weight: bold;
  font-size: 1.52rem;
  color: #25ace3;
  text-decoration: none !important;
}

.mobile-no {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  color: #40d48a !important;
  font-size: 1.5rem !important;

  font-weight: bolder !important;
}

.outer-wrapper > a {
  text-decoration: none;
}

.dark-outer-wrapper > a {
  text-decoration: none;
}

.home-img {
  background-size: cover;
  background-position: 50%;
}

.dark-outer-wrapper {
  display: inline-block;
}

.frame {
  width: 200px;
  height: 150px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.frame-card {
  width: 200px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  position: relative;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.d100-vh {
  height: 100vh;
}

input :not(input) {
  color: #000;
  font-family: "Courier New", Courier, monospace;
  font-size: 5.8rem;
  font-weight: bolder !important;
}

.creadit-text {
  color: #74d099;
  font-size: 1.5rem;
}

::-webkit-input-placeholder {
  /* Edge */
  /* color: rgb(130,130,130) !important; */
  font-weight: 600;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 1 rem;
}

input[type="text"],
input[type="mobile"],
input[type="email"],
input[type="number"],
input[type="password"] {
  font-weight: 700;
}

.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  padding-top: 40px;
}

.auth::before {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(
    linear-gradient(rgba(238, 130, 238, 1), #9198e5, rgba(75, 0, 130, 1))
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(238, 130, 238, 1),
    #9198e5,
    rgba(75, 0, 130, 1)
  );
  background: linear-gradient(
    90deg,
    rgba(238, 130, 238, 1),
    #9198e5,
    rgba(75, 0, 130, 1)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000", endColorstr="#ee82ee", GradientType=1);
}

.auth .brand {
  width: auto;
  height: 70px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.auth .brand img {
  width: 100%;
}

.auth .card-wrapper {
  width: 490px;
  margin: 6rem;
  padding: 0;
}

.auth .card {
  border-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.auth .card.fat {
  padding: 48px 68px;
}

.auth .card.fat .card-body {
  padding: 0;
}

.auth .card.fat .dark-card-body {
  padding: 0;
}

.auth .card.fat .dark-card-body form {
  margin-top: 32px;
}

.auth .card .card-title {
  font-weight: bold;
  padding: 12px 0;
  margin-bottom: 0;
  direction: ltr;
}

.auth .card .card-subtitle {
  padding: 12px 0;
  margin-bottom: 0;
}

.auth .card .card-title + .card-subtitle {
  padding-top: 0;
}

.auth .form-control {
  border-width: 2.3px;
  margin-top: 6px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  min-height: 34px;
  outline: none;
  padding: 0 8px;
  transition: all 0.2s ease;
}

.auth .form-group {
  margin-bottom: 12px;
}

.auth .form-group label,
.vehicle-stepper label,
.phone-form label {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12px 0 6px 0;
  font-size: 14px;
}

.auth .form-group a {
  color: #0080a3;
  text-decoration: none;
  vertical-align: baseline;
}

.auth .form-group.actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.auth .form-group.actions > button {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.1rem;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 48px;
  box-shadow: 0 3px 12px -3px rgb(106 106 112 / 75%);
  background-color: #00759c;
  border: 1px solid #00759c;
  border-radius: 3px;
}

.auth .btn.btn-block {
  padding: 12px 10px;
}

.auth .footer {
  margin: 40px 0;
  color: #888;
  text-align: center;
}

.auth a {
  text-decoration: none;
}

.auth a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 425px) {
  .auth .card-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .auth .card.fat {
    padding: 0;
  }

  .auth .card.fat .card-body {
    padding: 15px;
  }
}

.auth .card.fat .dark-card-body {
  padding: 15px;
}

[dir="ltr"] .form-control.is-invalid,
[dir="ltr"] .was-validated .form-control:invalid {
  background-position: right calc(0.375em + 0.1875rem) center;
}

i.asterisk {
  color: red;
}

.auth .custom-lang {
  position: absolute;
  top: 25px;
  right: 0;
}

.auth .custom-lang > button {
  color: #000 !important;
}

.container-input {
  height: 50px;
  width: 360px !important;
  font-size: 16px !important;
  margin-top: 24px;
}

.character {
  line-height: 50px;
  font-size: 16px;
  color: white;

  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-left: 8px;
}

.character:first-child {
  margin-left: 8px !important;
  font-size: 16px;
}

.character--inactive {
  background-color: #e9eaec !important;
  font-size: 16px;
}

.character--selected {
  border: 1px solid white;
  font-size: 16px;
}

.vi__character--default {
  font-size: 16px !important;
  border: 1px solid #e9eaec !important;
}

.dark-signin .fat {
  background: #242526;
}

.dark-signup .fat {
  background: #242526;
}

.dark-signup .text-center {
  color: white;
}

.dark-signin .text-center {
  color: white;
}

.dark-signin .text-center a {
  color: white;
}

.signIn-d {
  height: 900px;
}
.form-group iframe {
  pointer-events: auto !important;
  visibility: visible !important;
}