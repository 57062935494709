:root {
  --main-ooredoo: #c9262d;
  --back-ground: #db0b1b;
}
.oor-layout {
  background-color: #25ace4;
  color: #fff;
  font-weight: 900 !important;
}
.sub-text {
  color: #8dd5f4;
  font-weight: bolder;
  font-weight: bold;
  font-size: 1.52rem;
  color: #25ace3;
}
.ooredoo-text {
  color: var(--main-ooredoo);
  font-size: 1.8rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.ooredoo-sub {
  color: var(--main-ooredoo);
  font-size: 1rem;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.ooredoo-back {
  background-color: var(--back-ground);
}
.text-abs-oore {
  position: absolute;
  top: 26%;
  right: 12%;
  font-size: 2rem;
  font-weight: 900;
  color: #bb0d0e;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.card.ooredoo .nav-layout {
  background-color: #ec1c24;
}

.ooredoo-container .text-abs {
  position: absolute;
  top: 50%;
  left: calc(50% + 5px);
  font-size: 2rem;
  font-weight: 900;
  color: #14c259;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  transform: translateY(-50%);
  text-align: center;
  width: calc(50% - 10px);
}

.ooredoo-container .ooredoo-back {
  width: 100%;
  height: 100%;
  background: url(/src/assests/images/bundles/ooredoo/banner.jpg) no-repeat center center;
  background-size: cover;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.ooredoo-container .ooredoo-text {
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #fff;
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0 16px;
}
[lang="en"] .ooredoo-container .ooredoo-text {
  font-size: 1.2rem;
}

.orginal_Ooredoo_Company_Dark{
  text-align: center;
  margin-right: 30%;
  color: white;
}
.orginal_Ooredoo_Company{
  text-align: center;
  margin-right: 30%;
}
.Ooredoo_Orginal_Logo{
  margin-right: 40%;
  max-width: 400px;
}