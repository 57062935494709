.cellcom-back {
  background-color: #742e6d;
}
.pelephone-back {
  background-color: #0071b5;
}
.partner-back {
  background-color: #742e6d;
}
.golan-back {
  background-color: #ee1b24;
}
.mobile019-back {
  background-color: #742e6d;
}
.group-text {
  color: #ffffff;
  font-size: 2.8rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.group-back {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #25ace3;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.card.cellcom-back {
  background-image: url(/src/assests/images/bundles/cellcom/banner.png);
}
.card.pelephone-back {
  background-image: url(/src/assests/images/bundles/pelephone/banner.png);
}
.card.partner-back {
  background-image: url(/src/assests/images/bundles/partner/banner.png);
}
.card.golan-back {
  background-image: url(/src/assests/images/bundles/golan/banner.png);
}
.card.mobile012-back {
  background-image: url(/src/assests/images/bundles/mobile012/banner.png);
}

.card.mobile019-back {
  background-image: url(/src/assests/images/bundles/mobile019/banner.png);
}

.no-layout {
  background: #fff;
  border: 1px solid #25ace3;
  font-weight: bold;
  font-size: 1.52rem;
  color: #40d48a !important;
  text-decoration: none !important;
}
.mo-text {
  color: #000 !important;
  font-weight: 900 !important;
}

.card.cellcom .nav-layout {
  background-color: #752e6d;
}
.card.pelephone .nav-layout {
  background-color: #0072b6;
}
.card.golan .nav-layout {
  background-color: #ee1b24;
}
.card.mobile019 .nav-layout {
  background-color: #e51b38;
}
.card.partner .nav-layout {
  background-color: #29d6c5;
}

.list-cards .frame-card {
  width: 100%;
  height: 100%;
}

.list-cards .charge-card {
  margin-bottom: 10px;
}
.card.mobile012 .nav-layout span {
  direction: ltr;
  display: inline-block;
}

.img__wrap {
  position: relative;

}
.img__description {
  position: absolute;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  top: 20px;
  right: -30px;
z-index: 10000;
  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}
.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}
.input_Mobile_Color{
  color: white;
}