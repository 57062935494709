.widget-flat {
  position: relative;
  overflow: hidden;
  background-color: #e9eaec;
  max-width: 250px;
  min-height: 515px;
  overflow: hidden;
}

[dir="ltr"] .widget-flat .card-body .float-end {
  float: right !important;
}

[dir="ltr"] .widget-flat .dark-card-body .float-end {
  float: right !important;
}

.widget-flat .card-body .fw-normal {
  font-size: 1rem;
}

.dark-card-body {
  flex: 1 1 auto;
  padding: 1rem 0rem;
}

.widget-flat .dark-card-body .fw-normal {
  font-size: 1rem;
}
.card h6 {
  padding: 0 8px;
}
.widget-flat .widget-icon {
  color: #727cf5;
  font-size: 20px;
  background-color: rgba(114, 124, 245, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.user-info {
  display: flex;
  padding: 5px;
  background-color: #c0c4c785;
  border-radius: 0.25rem;
}

.user-info h6 {
  margin-bottom: 0;
}

.widget-flat .card-body .username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-flat .dark-card-body .username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.widget-flat a {
  text-decoration: none;
  color: unset;
}

hr.divider {
  margin: 0;
  border-bottom: 1px dashed indigo;
  background: transparent;
}

.dark-card-body hr.divider {
  margin: 0;
  border-bottom: 1px dashed rgb(246, 242, 250);
  background: transparent;
}

.card.card-currency {
  max-width: 250px;
  height: 515px;
}

.card.card-currency .card-body {
  background-color: #e9eaec;
  border-radius: 0.25rem;
  min-height: 375px;
}

.card.card-currency .dark-card-body {
  background-color: #242526;
  border-radius: 0.25rem;
  min-height: 375px;
}

.card.card-currency .dark-card-body h6 {
  color: white;
}

.card.card-currency table.currecy-rates {
  width: 100%;
}

.card.card-currency table.currecy-rates td,
.card.card-currency table.currecy-rates th {
  border: 1px solid #dee2e6;
  padding: 8px 12px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 13px;
  text-align: center;
}

.card.card-currency .dark-card-body table.currecy-rates tr th:nth-child(2n + 1),
.card.card-currency
  .dark-card-body
  table.currecy-rates
  tr
  td:nth-child(2n + 1) {
  width: 70%;
  color: white;
}

.card.card-currency .dark-card-body table.currecy-rates tr th:nth-child(2n),
.card.card-currency .dark-card-body table.currecy-rates tr td:nth-child(2n) {
  width: 30%;
  color: white;
}

.card.card-currency table.currecy-rates tr th:nth-child(2n + 1),
.card.card-currency table.currecy-rates tr td:nth-child(2n + 1) {
  width: 70%;
}

.card.card-currency table.currecy-rates tr th:nth-child(2n),
.card.card-currency table.currecy-rates tr td:nth-child(2n) {
  width: 30%;
}

.add-banner {
  width: 220px;
  max-width: 270px;
  height: 310px;
  border-radius: 0.25rem;
  margin-top: 40px;
}

/* .add-dark-banner {
  width: 220px;
  max-width: 270px;
  height: 310px;
  border-radius: 0.25rem;
  margin-top: 40px;
}

.add-dark-banner::before {
  background-color: #242526;
  content: '';
  display: block;
  height: 100%;
  opacity: .4;
  position: absolute;
  width: 100%;
} */
.card-body {
  padding: 1rem 0.5rem;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
