.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
    top: 3px !important;
}

.company-header {
    width: 100%;
    text-align: center;
    background-color: #324148 !important;
    padding: 15px 0;
}

.dark-text-feild {
    background: white;
    padding: 0px;
    border: 2px solid gray;
}

.dark-text-feild1 {
    /* background-color: white; */
    border: 2px solid gray;
}

.dark-contuct-us label {
    color: white;
}

.dark-contuct-us span {
    color: white;
}