.page-detail {
  padding: 24px 16px;
  border: 1px solid gainsboro;
}

.dark-page-detail {
  padding: 24px 16px;
  border: 1px solid #242526;
}

.page-detail .page-title {
  border-bottom: 1px solid #25ace3;
  color: #25ace3;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}

.dark-page-detail .page-title {
  border-bottom: 1px solid white;
  color: white;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}

.page-detail h6 {
  margin: 36px 0;
}

.dark-page-detail h6 {
  margin: 36px 0;
  color: white;
}

.transactions td {
  font-weight: bold;
  font-size: 12px !important;
}

.accordion-element {
  display: flex;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0;
  border-right: 0;
  padding: 12px 16px;
}

.custom-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  min-width: 110px;
}

.companies-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  height: 50px;
  direction: ltr !important;
}

.companies-nav .companies-actions {
  display: flex;
  justify-content: center !important;
}

.page-detail {
  direction: ltr !important;
}

.dark-companies-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  height: 50px;
  background: #242526;
  /* min-width:30% ; */
}

.companies-nav > div {
  display: flex;
  align-items: center;
}

.dark-companies-nav > div {
  display: flex;
  align-items: center;
}

.companies-nav p {
  margin-bottom: 0;
  margin-left: 16px;
}

.dark-companies-nav p {
  margin-bottom: 0;
  margin-left: 16px;
  color: white;
}

.companies-container {
  position: relative;
}

.companies-nav .companies-actions {
  background: linear-gradient(135deg, #00d2ff, #928dab);
  height: 100%;
  color: white;
  /* clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%); */
  justify-content: flex-end;
  padding: 0 12px;
}

.dark-companies-nav .companies-actions {
  background: linear-gradient(135deg, #00d2ff, #928dab);
  height: 100%;
  color: white;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
  justify-content: flex-end;
  padding: 0 12px;
}

body.arabic .companies-nav .companies-actions {
  clip-path: polygon(0 0, 50% 0%, 100% 100%, 0% 100%);
  margin-left: -12px;
}

body.arabic .navbar-collapse {
  display: flex;
  flex-direction: row-reverse;
}

body.arabic .navbar-collapse .dark-nav-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

body.english .navbar-collapse .ControlledSwitches.right {
  display: none !important;
}

body.arabic .navbar-collapse .ControlledSwitches.left {
  display: none !important;
}

body.arabic .dark-companies-nav .companies-actions {
  clip-path: polygon(0 0, 50% 0%, 100% 100%, 0% 100%);
  margin-left: -12px;
}

.companies-actions button {
  font-size: 12px;
  margin: 0 8px;
}

body.arabic .MuiSvgIcon-root {
  /*transform: rotateY(180deg) !important;*/
}

body.arabic .companies-nav .MuiSvgIcon-root {
  margin-right: 0px;
}

body.arabic .dark-companies-nav .MuiSvgIcon-root {
  margin-right: 0px;
}

.spanIcons svg {
  color: white;
}

.dark-table-wrapper
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: white;
}

.dark-table-wrapper
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #75787a;
}

.dark-table-wrapper
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #75787a;
}

.dark-stipper span {
  color: white;
}

.dark-stipper span.Mui-active {
  color: white;
}

.dark-stipper span.Mui-completed {
  color: white;
}

.dark-m svg {
  color: white;
}

.icon-shake {
  animation: shake 1.5s;
  animation-iteration-count: infinite;
}

.notification-container {
  width: 300px;
  min-height: 290px;
  max-height: 450px;
  background: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  position: absolute;
  top: 35px;
  border-radius: 00.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.notification-container .notification-message-container {
  padding-top: 16px;
  border-left: 6px solid #45b4e3;
  width: 100%;
  position: relative;
}

body.arabic .notification-container .notification-message-container {
  border-left: 0;
  border-right: 6px solid #45b4e3;
}

.notification-message-container.viewed {
  border-left: 6px solid gray;
  background: #f9f9f9;
  opacity: 0.7;
}

body.arabic .notification-message-container.viewed {
  border-left: 0;
  border-right: 6px solid gray;
}

.notification-message-container h6 {
  margin: 0 12px;
  margin-bottom: 8px;
  font-size: 13px;
  color: #45b4e3;
}

.notification-message-container.viewed h6,
.notification-message-container.viewed p {
  color: gray;
}

.notification-message-container p {
  margin: 0 12px;
  font-size: 10px;
  font-weight: 600;
}

.notification-message-container .border {
  margin: 16px;
  margin-bottom: 0;
}

body.english .notifications-icons {
  position: absolute;
  right: 15px;
  top: 8px;
}

body.english .notifications-icons-delete {
  position: absolute;
  right: 75px;
  top: 8px;
}

body.arabic .notifications-icons {
  position: absolute;
  left: 15px;
  top: 8px;
}

body.arabic .notifications-icons-delete {
  position: absolute;
  left: 75px;
  top: 8px;
}

body.english .notification-badge {
  left: 10px;
}

body.arabic .notification-badge {
  right: 10px;
}

.AdminMainPage h6 {
  color: white;
}

.date-picker {
  font-size: small;
  height: 32px;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0px, -10px);
  }
  60% {
    transform: translate(0px, -5px);
  }
}
