
.Company_Container {
    max-width: 400px;
    height: 175px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border: 3px solid green;
    border-radius: 10px;

}

.Company_Container_Dark {
    max-width: 400px;
    padding: 16px;
    background-color: #242526;
    margin-left: 22%;
    margin-right: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border: 3px solid green;
    border-radius: 10px;
}

.Company_Container_Red {
    max-width: 400px;
    height: 175px;
    padding: 16px;
    background-color: #242526;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border: 3px solid red;
    border-radius: 10px;
}

.Company_Container_white_green {
    max-width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border: 3px solid green;
    border-radius: 10px;
    height: 175px;
}

.Company_Container_white_Red {
    max-width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border: 3px solid red;
    border-radius: 10px;
    height: 175px;
}

.Company_Logo {
    max-width: 160px;
    max-height: 100px;
    margin-inline: 8px;
}

.attention_pay_div_container {
    display: flex;
    justify-content: center;
}

.Company_Provider_Dark {
    font-size: x-large;
    color: white;
    display: block;
}

.Company_Provider_Error {
    font-size: x-large;
    color: red;
}

.Company_Provider {
    padding-top: 16px;
    font-size: x-large;
    color: black;
    margin: 0;
    padding: 0;
}

.Correct_Provider_Logo {
    /* margin-right: 51.5%; */
    width: 250px;
    aspect-ratio: 1;
    margin-top: 25px;

}

.Fixed_logo_Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 720px;
    max-width: 950px;
}

