.discounts-page table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.discounts-page table td {
  font-size: 16px;
}

.discounts-page td,
.discounts-page th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px 0;
  font-size: 22px;
  width: 50%;
}

.discounts-page tr:nth-child(even) {
  background-color: #dddddd;
}

#page-number {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 30px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  font-weight: bold;
}

.pagging {
  margin: 0 -24px;
}
