.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.image {
  max-height: 350px;
  max-width: 350px;
  /* border-radius: 50%; */
}

.fakeImage {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #b7b8b9;
  display: flex;
  justify-content: center;
  align-items: center;
}
