.dateBicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.dark-dateBicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 650px;
    margin: 20px 0 40px 0;

}