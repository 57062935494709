@keyframes leftRightShake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}

.shake-effect {
    animation: leftRightShake 1s ease-in-out 3;
    transform-origin: top center;  /* Set the rotation point to the top of the icon */
}
